<template>
    <div>
        <b-card >  
            <b-card-title>
                <b-row >
                    <b-col xl="10" md="9" lg="10" class="mt-1">
                        <h3 >
                            Pesajes del Proyecto #{{this.project.projectCode}}
                        </h3>
                    </b-col>
                    <b-col xl="2" md="3" lg="2" style="margin-top:5px">                         
                        <b-button variant="primary" block @click="createWeight">
                            <feather-icon icon="PlusIcon" class="mr-50"/>
                            <span class="align-middle">Crear pesaje</span>
                        </b-button>
                    </b-col>  
                </b-row>                    
            </b-card-title>

            <b-table                 
                ref="selectableTable"
                :fields="fields" 
                :items="projectWeightsDetails"                
                responsive
                selectable
                class="mb-0"
                :select-mode="selectMode"
                @row-selected="onRowSelected">
                <template #cell(pesoManada)="data">                    
                    {{parseFloat(data.item.pesoManada).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg
                </template> 

                <template #cell(weightDate)="data">
                    {{((data.item.weightDate).substring(0,10))}}                    
                </template>  
            </b-table>            
            <b-row v-if="this.projectWeightsDetails.length <= 0" class="justify-content-center mt-3" style="text-align:center">
                <b-col style="justify-content:center" xl="4" md="6" lg="4"  >
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <span><strong>No Hay Pesajes para mostrar</strong></span>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
        </b-card>

        <b-modal size="sm" ref="create-new-weight" id="create-new-weight" centered hide-footer title="Digita la siguiente información">            
            <b-card-text>                 
                <b-form>
                    <b-row>
                        <b-col md="12" xl="12" class="mb-1">
                            <label for="example-datepicker" style="font-size:13px">Fecha pesaje</label>
                            <b-form-datepicker v-model="weightDate" id="example-datepicker1" class="mb-1"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <b-form-group>  
                                <span>Digite el peso de la manada en Kilogramos</span>                                                      
                                <b-input-group>
                                    <cleave 
                                        style="margin-left:0;margin-bottom:10px"                        
                                        id="vi-first-name"
                                        v-model.number="pesoManada"
                                        value-field="pesoManada"
                                        class="form-control "
                                        :options="options.number"                                                                                                                                  
                                        ref="myTextField"
                                        placeholder="Digite"/>                                
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <b-form-group>  
                                <span>Cantidad de Animales</span>                      
                                <b-form-input v-model.number="totalAnimals" type="number" placeholder="Digite"/>     
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <b-form-group>  
                                <span>Observaciones</span>  
                                <b-form-textarea v-model="observations" id="textarea-default" placeholder="Digite" rows="2"/>                                                                                  
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <b-form-checkbox v-model="this.sendNotificationCheck" @change="changeSendNotificationCheck()" checked="false" name="check-button" switch inline> 
                                Notificar Neoganaderos              
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1" style="text-align:right">
                        <b-col>
                            <b-button variant="primary" @click="SaveProjectWeight">
                                <feather-icon icon="SaveIcon" class="mr-50"/>
                                <span class="align-middle">Guardar</span>                                    
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>                
            </b-card-text>        
        </b-modal> 

        <b-modal size="sm" ref="edit-delete-weight" centered  id="edit-delete-weight" hide-footer title="Que deseas hacer?">                                                        
            <b-col xl="12" md="12" lg="12">            
                <b-row  style="text-align:center">                
                        <b-button class="mt-1" variant="primary" block @click="editWeight">
                            <feather-icon icon="EditIcon" class="mr-50"/>
                            <span class="align-middle">Editar Pesaje</span>                                    
                        </b-button>                
                </b-row>
                <b-row style="text-align:center">
                    <b-button class="mt-1" variant="warning" block @click="deleteWeight">
                        <feather-icon icon="DeleteIcon" class="mr-50"/>
                        <span class="align-middle">Borrar Pesaje</span>                                    
                    </b-button>                
                </b-row>
            </b-col>
        </b-modal> 
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol,  BLink, BTable, BCalendar, BFormDatepicker, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,  
  BCard, BModal, BAlert, BFormTextarea,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import axios from '@axios'
import { pausableFilter } from '@vueuse/shared'
import Cleave from 'vue-cleave-component'


export default {
  components: {
    BRow,
    BAlert,
    BCardCode,
    BCalendar,
    BCol,
    BFormTextarea,
    BTable,
    BLink,
    BCard,
    BFormDatepicker,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    BSpinner,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
        weightDate: null,
        pesoManada: null,
        cantidadDeAnimales: null,
        projectID: null,
        projectWeightID: null,
        projectWeightsDetails:[],
        weightSelectedByID: {},
        weightSelected: {},
        fields: [
            { key: 'weightDate', label: 'Fecha pesaje' }, 
            { key: 'totalAnimals', label: 'Cantidad de Animales'},
            { key: 'pesoManada', label: 'Peso de Manada' },  
            { key: 'observations', label: 'Observaciones' },
        ],
        selectedInvestment:{},
        selectMode: 'single',
        project:{},
        payload:{},
        options: {
            date: {
            date: true,
            delimiter: '-',
            datePattern: ['Y', 'm', 'd'],
            },
            number: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            },
        }, 
        sendNotificationCheck: false,
        observations: null,
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.projectID != null) {
      this.projectID = this.$route.query.projectID; 
      this.GetProjectWeights();
      this.GetProjectPublicById();
    } 
  },
  watch:{
      projectWeightID: function(val) { this.projectWeightID = val; },
  },
  methods:{
    GetProjectWeights(){
      this.processing = true;
        axios.get('/Project/GetProjectWeights', { params: { projectID: this.projectID }})
        .then(response => {
            this.projectWeightsDetails = response.data;            
        })
    },
    GetProjectPublicById(){
        axios.get('/Project/GetProjectPublicById', { params: { projectID: this.projectID }})
        .then(response => {
          this.processing = false;
          this.project = response.data;          
        })
    },
    createWeight(){        
        if(this.weightSelected.projectWeightID != null || this.weightSelected.projectWeightID != undefined){  
            this.weightSelected = undefined
            this.projectID = null,
            this.weightDate = null,
            this.pesoManada = null,
            this.totalAnimals = null,
            this.observations = null
        }               
        this.$refs['create-new-weight'].show()          
    },
    SaveProjectWeight(){                
        if(this.weightDate == null || this.pesoManada == null || this.totalAnimals == null){
            this.$bvToast.toast("Debes diligenciar todos los campos", { title: 'Email', variant: 'danger', solid: true, })     
        } else {
                this.weightSelected.projectID = this.projectID,                
                this.weightSelected.weightDate = this.weightDate,
                this.weightSelected.pesoManada = this.pesoManada,
                this.weightSelected.totalAnimals = this.totalAnimals,
                this.weightSelected.observations = this.observations

            var payload = {                
                weightSelected: this.weightSelected,
                sendNotificationCheck: this.sendNotificationCheck
            }
            axios.post('/Project/SaveProjectWeight', payload)
            .then(response => {
                this.processing = false;  
                this.hideWeightModal();
                if(response.data) {     
                    this.$bvToast.toast("Información guardada de forma exitosa", { title: 'Ok', variant: 'success', solid: true, });  
                    this.project = response.data;          
                } else {
                    this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
                }
                window.location.reload()   
            })
        }
    },
    editWeight(){
        this.GetProjectWeightByID()         
    },
    GetProjectWeightByID(){  
        this.projectWeightID = this.weightSelected.projectWeightID 
        axios.get('/Project/GetProjectWeightByID', { params: { projectWeightID: this.projectWeightID}})
        .then(response => {
            this.weightSelected = response.data;
            this.projectWeightID = this.weightSelectedByID.projectWeightID;
            this.projectID = this.weightSelected.projectID,                
            this.weightDate = this.weightSelected.weightDate,
            this.pesoManada = (this.weightSelected.pesoManada),            
            this.totalAnimals = this.weightSelected.totalAnimals,
            this.observations = this.weightSelected.observations
            
            this.hideEditDeleteModal();
            this.showWeightModal();
        })
    },
    deleteWeight(){
        this.projectWeightID = this.weightSelected.projectWeightID 
        axios.get('/Project/DeleteWeight', { params: { projectWeightID: this.projectWeightID}})
        .then(response => {                 
            this.hideEditDeleteModal();
            if(response.data) {     
                this.$bvToast.toast("Peso ha sido borrado", { title: 'Ok', variant: 'success', solid: true, });  
                this.project = response.data;          
            } else {
                this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
            }
            window.location.reload()   
        })

    },
    onRowSelected(items) {
        this.weightSelected = items[0];
        if(this.weightSelected)         
        this.showModalToEditDelete();        
    },
    showWeightModal() {
        this.$refs['create-new-weight'].show()         
    },
    hideWeightModal(){
        this.$refs['create-new-weight'].hide()                  
    },
    showModalToEditDelete() {           
        this.$refs['edit-delete-weight'].show()         
    },
    hideEditDeleteModal(){
        this.$refs['edit-delete-weight'].hide() 
    },
    changeSendNotificationCheck(){
        if(this.sendNotificationCheck == true){
            this.sendNotificationCheck = false
        } else{
            this.sendNotificationCheck = true
        }
        
    }
  }
}
</script>
